const initialState = {
    isLoading: false,
    list: [],
    error: null,
};

export const userStatsDetailsReducers = (state = initialState, action) => {
    switch(action.type){
        case "FETCH_USER_TRANSACTIONS":
        return {
            ...state,
            isLoading: true,
        };
        case "FETCH_USER_TRANSACTIONS_SUCCESS":
        return {
            ...state,
            list: action.payload,
            isLoading: false,
            error: null,
        };
        case "FETCH_USER_TRANSACTIONS_FAILURE":
        return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        default:
        return state;
    }
}

