const initialState = {
    isLoading: false,
    list: [],
    error: null,
    dogNames: [],
};

export const dogReducers = (state = initialState, action) => {
    switch(action.type){
        case "FETCH_DOGS":
        return {
            ...state,
            isLoading: true,
        };
        case "FETCH_DOGS_SUCCESS":
        return {
            ...state,
            list: action.payload,
            isLoading: false,
            error: null,
        };
        case "FETCH_DOGS_FAILURE":
        return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        case "FETCH_DOG_NAMES_SUCCESS":
        return {
            ...state,
            dogNames: action.payload,
            isLoading: false,
            error: null,
        };
        case "FETCH_DOG_NAMES_FAILURE":
        return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        default:
        return state;
    }
}

