import { combineReducers } from "redux";
import calenderReducer from "./calendar/";
import emailReducer from "./email/";
import chatReducer from "./chat/";
import todoReducer from "./todo/";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import dataList from "./data-list/";
import page from "./page/";
import blog from "./blog/";
import dogs from "./dogs/";
import details from "./details/";
import users from "./user/";
import games from "./games/";
import usersHomeDetails from "./userhomedetails/";
import usersStatsDetails from "./userstatsdetails/";
import dogsProfitLossStats from "./dogs-profit-loss";

const rootReducer = combineReducers({
  calendar: calenderReducer,
  emailApp: emailReducer,
  todoApp: todoReducer,
  chatApp: chatReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  page: page,
  blog: blog,
  dogs: dogs,
  details: details,
  users: users,
  games: games,
  usersHomeDetails: usersHomeDetails,
  usersStatsDetails: usersStatsDetails,
  dogsProfitLossStats: dogsProfitLossStats,
});

export default rootReducer;
