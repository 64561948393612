const initialState = {
    isLoading: false,
    list: [],
    error: null,
};

export const detailReducers = (state = initialState, action) => {
    switch(action.type){
        case "FETCH_DETAILS":
        return {
            ...state,
            isLoading: true,
        };
        case "FETCH_DETAILS_SUCCESS":
        return {
            ...state,
            list: action.payload,
            isLoading: false,
            error: null,
        };
        case "FETCH_DETAILS_FAILURE":
        return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        default:
        return state;
    }
}

