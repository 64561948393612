import MainLogo from "./main-logo.png";
import BackgroundImage from "./background-image.png";
import Numbers from "./numbers.png";
import FooterBackground from "./footer-background.png";
import FullFooterBG from "./full-footer-bg.png";
import SidebarBg from "./sidebar-bg.png";
import Shiba from "./shiba.png";
import Floki from "./floki.png";
import Doge from "./doge.png";
import Vita from "./vita.png";
import Kishu from "./kishu.png";
import BabyDoge from "./baby-doge.png";
import TimerBg from "./timer-bg.png";

export { MainLogo, BackgroundImage, Numbers, FooterBackground, FullFooterBG, SidebarBg, Shiba, Floki, Doge, Vita, Kishu, BabyDoge, TimerBg };