const initialState = {
    isLoading: false,
    list: [],
    error: null,
};

export const userHomeDetailsReducers = (state = initialState, action) => {
    switch(action.type){
        case "FETCH_USER_DETAILS":
        return {
            ...state,
            isLoading: true,
        };
        case "FETCH_USER_DETAILS_SUCCESS":
        return {
            ...state,
            list: action.payload,
            isLoading: false,
            error: null,
        };
        case "FETCH_USER_DETAILS_FAILURE":
        return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        default:
        return state;
    }
}

