const initialState = {
    isLoading: false,
    list: [],
    error: null,
};

export const dogsProfitLoss = (state = initialState, action) => {
    switch(action.type){
        case "FETCH_DOGS_PROFIT_LOSS":
        return {
            ...state,
            isLoading: true,
        };
        case "FETCH_DOGS_PROFIT_LOSS_SUCCESS":
        return {
            ...state,
            list: action.payload,
            isLoading: false,
            error: null,
        };
        case "FETCH_DOGS_PROFIT_LOSS_FAILURE":
        return {
            ...state,
            isLoading: false,
            error: action.payload,
        };
        default:
        return state;
    }
}

