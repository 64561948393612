import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  // {
  //   id: "dynamic-pages",
  //   title: "Dashboard",
  //   type: "item",
  //   icon: <Icon.List size={20} />,
  //   navLink: "/admin/reports"
  // },
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/home",
    permissions: ["admin"]
  },
  {
    id: "users",
    title: "Users",
    type: "item",
    icon: <Icon.User size={20} />,
    navLink: "/dashboard",
    permissions: ["admin"]
  },
  {
    id: "games",
    title: "Games",
    type: "item",
    icon: <Icon.PlayCircle size={20} />,
    navLink: "/games",
    permissions: ["admin"]
  },
  {
    id: "dogs",
    title: "Dogs",
    type: "item",
    icon: <Icon.Eye size={20} />,
    navLink: "/dogs",
    permissions: ["admin"]
  },
  {
    id: "dog-names",
    title: "Dog-Names",
    type: "item",
    icon: <Icon.Eye size={20} />,
    navLink: "/dog-names",
    permissions: ["admin"]
  },
  {
    id: "userHome",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/user-home",
    permissions: ["player"]
  },
  {
    id: "userStats",
    title: "Stats",
    type: "item",
    icon: <Icon.BarChart size={20} />,
    navLink: "/user-stats",
    permissions: ["player"]
  },
  {
    id: "withdraw",
    title: "Withdraw",
    type: "item",
    icon: <Icon.BarChart size={20} />,
    navLink: "/withdraw",
    permissions: ["admin"]
  },
]

export default navigationConfig
